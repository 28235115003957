import React, { Component } from 'react'
import { graphql } from 'gatsby'
import he from 'he'
//import Fade from 'react-reveal/Fade'
import RenderBlock from '../utils/render-block'

import SEO from '../components/seo'

class ProjectTemplate extends Component {

  render() {
    let {
      data: { project },
    } = this.props

    let metaDescription = project.yoast_meta.find(x => x.name === 'description') ? project.yoast_meta.find(x => x.name === 'description').content : '';

    return (
      <>
        <SEO
          title={he.decode(project.yoast_title)}
          bodyClass={`project`}
          description={metaDescription}
        />
        <section className='content content--project'>
          <div className='content__inner'>
            <div className='content__intro' dangerouslySetInnerHTML={{ __html: project.acf.content }} />
          </div>
        </section>
        { project.acf.project_blocks_project && project.acf.project_blocks_project.map((el, i) => {
          return RenderBlock(el.__typename, el, i)
        }) }
        <section className='content content--details'>
          <div className='content__inner'>
            <div className='content__items'>
              <div className='content__item'>
                <h3>Client</h3>
                <div dangerouslySetInnerHTML={{ __html: project.acf.client }} />
              </div>
              <div className='content__item'>
                <h3>Approach</h3>
                <div dangerouslySetInnerHTML={{ __html: project.acf.approach }} />
              </div>
              <div className='content__item'>
                <h3>Solution</h3>
                <div dangerouslySetInnerHTML={{ __html: project.acf.solution }} />
              </div>
            </div>
          </div>
        </section>
      </>
    )
  }
}

export const projectQuery = graphql`
  query($id: String!) {
    project: wordpressWpProject(id: { eq: $id }) {
      id
      title
      slug
      yoast_title
      yoast_meta {
       name
       content
       property
      }
      acf {
        project_type
        content
        project_blocks_project {
          __typename
          ... on WordPressAcf_image {
            image {
              alt_text
              source_url
              localFile {
                childImageSharp {
                  original {
                    src
                  }
                }
              }
            }
          }
          ... on WordPressAcf_video {
            video
            video_poster {
              alt_text
              source_url
              localFile {
                childImageSharp {
                  original {
                    src
                  }
                }
              }
            }
          }
          ... on WordPressAcf_testimonial {
            image {
              alt_text
              source_url
              localFile {
                childImageSharp {
                  original {
                    src
                  }
                }
              }
            }
            quote
            name
          }
        }
        client
        approach
        solution
      }
    }
  }
`

export default ProjectTemplate
